import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, PaginationSimple, BooksBookYear } from "../components"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import slugifyPost from "../../slugifyPost"

const SingleBookYearPage = ({ data, pageContext }) => {
  const { bookYear } = data.contentfulBookYear

  // console.log("fullname, created", authorFullName, createdAt)

  // console.log("autors data", data)
  const { previous, next } = pageContext

  // console.log("previous", previous)

  // const{
  //   contentfulBookYear: { book: books },
  // } = data

  const books = data.contentfulBookYear.book

  //   const books = data.contentfulAuthor.book
  //     ? data.contentfulAuthor.book
  //     : data.contentfulAuthor.magazine

  //   const category = data.contentfulAuthor.book ? "book" : "magazine"

  // console.log("authors", authors)

  const prevTitle = previous ? previous.bookYear : null
  const nextTitle = next ? next.bookYear : null
  // const prevRow = previous ? previous.bookYear : null
  // const nextRow = next ? next.bookYear : null

  const prevRow = ""
  const nextRow = ""

  const allItemPage = "/translation/booksyear/1"
  const oneItemPage = "/translation/booksbookyear/"

  return (
    <Wrapper>
      <Layout>
        <div className="section-center">
          <h1>{bookYear}</h1>
          <BooksBookYear
            title="Összes fordítás ebben az évben"
            books={books}
            page
          />
        </div>
        <PaginationSimple
          oneItemPage={oneItemPage}
          allItemPage={allItemPage}
          prevTitle={prevTitle}
          nextTitle={nextTitle}
          prevRow={prevRow}
          nextRow={nextRow}
          pageContext={pageContext}
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-light-5);
  padding-top: 9rem;
  padding-bottom: 0rem;
  color: var(--clr-primary-4);

  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .section-center {
    h1 {
      text-align: center;
    }
    h2 {
      text-align: center;
      padding-bottom: 0rem;
    }

    h3 {
      ${"" /* text-align: center; */}
      padding-bottom: 1rem;
      padding-top: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }

    h4 {
      text-align: center;
      ${"" /* text-transform: uppercase; */}
    }

    .publisher {
      font-weight: bold;
    }

    .date-page {
      text-transform: lowercase;
      padding-bottom: 2rem;
      font-weight: bold;
    }

    .info {
      padding-left: 10%;
      padding-bottom: 2rem;
      color: var(--clr-grey-1);
      border-left: 2px solid var(--clr-primary-5);
    }
  }

  .img {
    border-radius: var(--radius);
    transition: var(--transition);
  }

  a {
    ${"" /* display: block; */}
    width: auto;
    text-align: center;
    ${"" /* padding: 1rem 1rem; */}
    margin: 0 auto;
    ${"" /* margin-top: 1rem; */}
    color: var(--clr-light-3);
  }

  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }

  @media (min-width: 768px) {
    .section-center {
      width: 80%;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      width: 70%;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      width: 60%;
    }
  }
`

export const query = graphql`
  query getTranslationBookYear($recordId: String!) {
    contentfulBookYear(contentful_id: { eq: $recordId }) {
      bookYear
      contentful_id
      book {
        contentful_id
        bookTitle
        author {
          authorFullName
        }

        publisher {
          publisherName
        }
        bookSize
        bookText {
          childMarkdownRemark {
            excerpt(pruneLength: 100)
          }
        }
        bookImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
      }
    }
  }
`

export default SingleBookYearPage
